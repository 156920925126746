.input-container {
  width: 50%;
  padding: 0rem 1rem;
}

.single-input {
  width: 100%;
}

.input {
  max-width: 100% !important;
}

.modal-dialog {
  max-width: 67%;
}

.input-lable {
  margin-bottom: 0 !important; 
}

.input-group {
  margin: 0.5rem 0;
}

.table-btn {
  background: black;
  margin: 1rem 0.5rem;
}