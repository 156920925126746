.logo {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
  height: auto;
}
.intro {
  display: block;
  margin-top: 2rem;
  text-align: center;
  font-size: 20px;
}
.labels {
  font-size: 18px;
}
.user-details {
  font-size: 18px;
  margin-top: 8px;
}
