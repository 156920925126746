.NavBar {
  background-color: #f57920;
  font-family: garamond;
  min-height: 10vh;
  font-size: calc(10px + 2vmin);
}
.link {
  display: inline-block;
  color: black;
  margin: 0.5rem 1rem;
  width: 11rem;
  font-size: 2rem;
  border: solid black 3px;
  border-radius: 0.3rem;
  color: white;
  background: black;
}
.link:hover {
  background: #f57920;
  color: black;
  text-decoration: none;
}
.button-link {
  background-color: black;
  border: 1px solid white;
  color: #fff;
  font-size: calc(10px + 2vmin);
  width: 150px;
}
.hamburger {
  width: 35px;
  height: 5px;
  background-color: black;
  margin: 6px 0;
}
.menu-icon {
  /*background: #484848;*/
  background: none;
  padding: 0.3rem 0.5rem;
}
.center-menu-link {
  text-align: center;
}
.dropdown-menu {
  top: 100% !important;
  left: 25% !important;
  transform: none !important;
}