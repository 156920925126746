.Home {
  color: black;
  text-align: center;
  padding: 10px 10px;
}

.row {
  size: 100%;
  justify-content: center;
}

.col-header {
  font-family: garamond;
  font-size: 50px;
  font-weight: bold;
  color: black;
  font-style: normal;
}

.logged-in {
  font-family: garamond;
  font-size: 40px;
  color: #a5acaf;
  font-style: italic;
}

.container {
  font-family: garamond;
  text-align: center;
  width: 500px;
  height: 200px;
  padding-top: 40px;
}

.primaryButton {
  display: block;
  text-align: center;
  color: black;
  background-color: #a5acaf;
  justify-content: center;
  position: relative;
  margin: 20px;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.secondaryButton {
  display: block;
  text-align: center;
  color: black;
  background-color: #f57920;
  justify-content: center;
  position: relative;
  margin: 20px;
  left: 50%;
  -ms-transform: translate(-50%);
  transform: translate(-50%);
}

.vertical-logo {
  max-width: 798px;
  width: 50%;
  height: auto;
}
