.plansImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:auto;
    width: 50%;
}
.dropdown-menu-category {
    display: inline-block;
}
.filter-videos{
    margin-top: 25px;
    width: 50%;
}

.card-title {
    text-align: center;
}

.card {
    min-width: 300px;
    min-height: 200px;
    background-color: #f57920;
    box-shadow: 10px 10px 5px grey;
}

.card-deck .card {
    margin-bottom: 15px;
}

.videoLink {
    text-align: center;
}

.videoPlayer {
    width: 90%;
    max-height: 223px;
}
