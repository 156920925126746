.player-window {
    position: relative;
    border: solid 1px;
    margin-top: 2rem;
    margin-bottom: 0.5rem;
}

.container-center {
    margin: auto;
    width: 70%;
}

.details:after {
    float: right;
    content: "\2807";
    font-size: 39px;
    top: -1px;
    right: -3px;
    position: relative;
}

.video-detail {
    font-weight: bold;
}

.modal-cancel {
    background-color: #000000;
}

.title {
    font-size: 2rem;
    font-weight: bold;
}

.alignRight {
    text-align: right;
}
