.video-player {
    display: block;
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.video-container{
    margin-top: 50px;
}

.video {
    width: 1246px;
    height: 698px;
}