.App {
  font-family: Baskerville;
  position: relative;
}

.App-body {
  background-color: #282c34;
  min-height: 60vh;
  padding: 1em 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

/* all buttons in the App component should be styled like this */
/* .App button {
  background-color: black;
  border: 1px solid white;
  color: #fff;
  box-shadow: 0 0 4px 0 white;
  font-size: calc(10px + 2vmin);
  margin-top: 20px;
  width: 150px;
  padding: .25em;
} */

/* when clicked, what should change? */
.App button:active {
  background-color: grey;
}

.App button:active,
.App button:focus {
  outline: 0;
}
