table, th, td {
    border: 1px solid black;
  }

table {
    width: 100%;
    border-right: none;
    border-bottom: none;
}

th, td {
    text-align: center;
}

.edit {
    width: 10%;
    height: auto;
}

.editRow {
    text-align: left;
    border: none;
}