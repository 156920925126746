.PleaseLogin {
  display: block;
  margin-top: 2rem;
  text-align: center;
}

.logo {
  display: block;
  padding: 5em;
  max-width: 798px;
  width: 70%;
  height: auto;
}
